<template>
  <LineItem
    v-bind="{ addOns, fields, licensedTerritory, lotteryId }"
    class="tier-table-line-item"
    size="small"
    spacing="small"
    :style="theme"
  >
    <template #field-divider>
      <span class="font-medium">
        {{ $t('tiertable.fielddivider') }}
      </span>
    </template>
  </LineItem>
</template>

<script lang="ts">
import type { LineItemProps } from '~/components/LineItem/LineItem.vue'

export const NO_MATCH_FIELD_NAME = 'NO-MATCH'
</script>

<script lang="ts" setup>
type LotteryTierTableLineItemProps = Pick<
  LineItemProps,
  'addOns' | 'fields' | 'licensedTerritory' | 'lotteryId'
>

const props = defineProps<LotteryTierTableLineItemProps>()

const theme = computed(() => {
  const { createCssVar } = getLotteryCssVarConstructor(
    props.licensedTerritory,
    props.lotteryId,
  )

  return {
    '--lottery-numbers-extra-font-size': createCssVar('extra-ball-font-size'),
    '--lottery-numbers-extra-font-weight': createCssVar(
      'extra-ball-font-weight',
    ),
  }
})
</script>

<style lang="scss" scoped>
.tier-table-line-item {
  --lottery-numbers-no-match-bg: none;
  --lottery-numbers-no-match-border: 2px dashed #{color('secondary500')};
}
</style>
