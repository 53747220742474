<template>
  <section>
    <LcTable
      v-for="({ headline, prizeTiers }, index) in groupedPrizeTiers"
      :key="index"
      bordered
    >
      <thead v-if="headline">
        <tr>
          <th colspan="2" scope="col">{{ headline }}</th>
        </tr>
      </thead>
      <thead v-else-if="isPickLottery">
        <tr>
          <th scope="col">
            {{ $t('tiertable.header.playtype') }}
          </th>
          <th class="text-right" scope="col">
            {{ $t('tiertable.header.prizeamount') }}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="prizeTier in getPreparedPrizeTiers(prizeTiers)"
          :key="prizeTier.tier"
        >
          <td>
            <template v-if="isNumberLottery">
              <TierTableLineItem
                v-bind="{ addOns, licensedTerritory, lotteryId }"
                :fields="getFields(prizeTier.requirements)"
              />
            </template>
            <template v-else-if="isPickLottery">
              {{ getPerPlayTranslation(lotteryId, 'betType', prizeTier.tier) }}
            </template>
          </td>

          <td class="font-medium text-right">
            <template v-if="type === 'winners'">
              <div>{{ prizeTier.winners ?? 0 }}</div>
            </template>
            <template v-else>
              <template
                v-if="
                  !prizeTier.prize?.value &&
                  prizeTier.type === PrizeTierType.Jackpot
                "
              >
                {{ $t('tiertable.jackpot') }}
              </template>
              <template v-else-if="prizeTier.type === PrizeTierType.FreeTicket">
                <div class="text-success600 text-uppercase">
                  {{ $t('tiertable.freeticket') }}
                </div>
              </template>
              <template v-else>
                <div>{{ new Money(prizeTier.prize) }}</div>

                <div v-if="prizeTier.payoutInterval !== PayoutInterval.Once">
                  {{
                    getTranslation({
                      key: `tiertable.payoutinterval.${prizeTier.payoutInterval}`,
                    })
                  }}
                </div>
              </template>
            </template>
          </td>
        </tr>
      </tbody>
    </LcTable>
  </section>
</template>

<script lang="ts" setup>
import { type LcLotteryNumber, LcTable } from '@lottocom/frontend-components'

import {
  type AddOn,
  LotteryType,
  type Money as MoneyType,
  type NumberLottery,
  type NumberRequirement,
  PayoutInterval,
  type PrizeTierRequirements,
  PrizeTierType,
} from '~/@types/generated/backend/graphql-schema-types'
import type {
  LineItemField,
  LineItemProps,
} from '~/components/LineItem/LineItem.vue'
import { NO_MATCH_FIELD_NAME } from '~/components/TierTable/TierTableLineItem.vue'
import Money from '~/models/Money'

export type TierTableType = 'prizes' | 'winners'

export type TierTablePrizeTier = {
  payoutInterval: PayoutInterval
  prize?: Maybe<MoneyType>
  requirements: PrizeTierRequirements
  tier: string
  type: PrizeTierType
  winners?: Maybe<number>
}

export type TierTableGroupedPrizeTier = {
  headline?: Maybe<string>
  prizeTiers: TierTablePrizeTier[]
}

export type TierTableProps = Pick<
  LineItemProps,
  'licensedTerritory' | 'lotteryId'
> & {
  addOns: AddOn[]
  groupedPrizeTiers: TierTableGroupedPrizeTier[]
  lotteryType?: Maybe<LotteryType>
  type: TierTableType
}

const GROUPABLE_TIERS = ['pair', 'wheel']

const isNoMatch = ({ amountOfCorrectNumbers }: NumberRequirement) =>
  !amountOfCorrectNumbers

const hasNumberLotteryRequirements = (
  requirements: PrizeTierRequirements,
): requirements is NumberLottery =>
  !!(requirements as NumberLottery).numberRequirements

const props = defineProps<TierTableProps>()

const { getTranslation } = useI18nUtils()
const { getPerPlayTranslation } = useTranslations()

const isNumberLottery = computed(
  () => props.lotteryType === LotteryType.NumberLottery,
)
const isPickLottery = computed(
  () => props.lotteryType === LotteryType.PickLottery,
)

const getFields = (requirements: PrizeTierRequirements) => {
  if (!hasNumberLotteryRequirements(requirements)) return []

  const { numberRequirements } = requirements
  if (numberRequirements.every(isNoMatch)) {
    return [
      {
        name: NO_MATCH_FIELD_NAME,
        numbers: [{ value: '' }],
      },
    ]
  }

  return numberRequirements.map<LineItemField>(
    ({ amountOfCorrectNumbers, numbersReference }) => ({
      name: numbersReference,
      numbers: new Array<LcLotteryNumber>(amountOfCorrectNumbers).fill({
        value: getTranslation({
          key: `tiertable.ball.${numbersReference}.${props.lotteryId}`,
        }),
      }),
    }),
  )
}

const getPreparedPrizeTiers = (prizeTiers: TierTablePrizeTier[]) => {
  if (isNumberLottery.value) return prizeTiers

  const groupedTiers: TierTablePrizeTier[] = []

  prizeTiers.forEach((tier) => {
    const tierGroup = GROUPABLE_TIERS.find((groupableTier) =>
      tier.tier.includes(groupableTier),
    )

    if (!tierGroup) {
      groupedTiers.push(tier)
      return
    }

    if (groupedTiers.some(({ tier }) => tier === tierGroup)) return

    groupedTiers.push({
      ...tier,
      tier: tierGroup,
    })
  })

  return groupedTiers
}
</script>
